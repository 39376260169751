import React from 'react'
import './NextEvents.css'
import Slider from '../../Slider/Slider';

import Miura from '../../../assets/images/nextEvents/miura.png'
import Argy from '../../../assets/images/nextEvents/Argy.png'
import versosvivos from '../../../assets/images/nextEvents/Festival-Versos-Vivos.png'
import hc from '../../../assets/images/nextEvents/HC.png'
import Gardenh from '../../../assets/images/nextEvents/Garden-Halloween-rs.png'
import Hweeknd from '../../../assets/images/nextEvents/Halloween-Weekend-oguz.png'


import parfait from '../../../assets/images/nextEvents/999999999.JPG'
import wecomeback from '../../../assets/images/nextEvents/WELCOME-BACK.JPG'
import sasha from '../../../assets/images/nextEvents/SASHA-SE-DIGWEED.jpg'
import mission from '../../../assets/images/nextEvents/THE-MISSION-MEX.png'
import pauloak from '../../../assets/images/nextEvents/PAUL-OAKENFOLD.jpeg'
import nocheav from '../../../assets/images/nextEvents/JUAN-CARLOS-ALVARADO.jpeg'

const nextEvents = [
  {
    image: Miura,
    title: 'Miura',
    buy: 'https://boletos.miuraboxing.com'
  },
  // {
  //   image: SkaNPunk,
  //   title: 'Ska N’ Punk',
  //   buy: 'https://arema.mx/e/12230'
  // },

  {
    image:Argy ,
    title: 'Argy / Heik - Johnny Chief - Fakes',
    buy: 'https://www.ticketfairy.com/event/argy-looloo'
  },
  {
    image:versosvivos ,
    title: 'Festival Versos Vivos ',
    buy: 'https://festivalversosvivos.ticketapp.mx '
  },
  {
    image:hc ,
    title: 'Hernan Cattaneo',
    buy: 'https://hc24.boletia.com'
  },  
  {
    image:Gardenh ,
    title: 'Garden Halloween: Roger Sanchez + DJ Chus',
    buy: 'https://gardenclub.com.mx/product/garden-halloween/'
  },
  {
    image:Hweeknd ,
    title: 'Halloween Weekend / Oguz - Süss - Ann García - Oktopuzzy',
    buy: 'https://www.ticketfairy.com/event/oguz'
  },
  // {
  //   image: indira,
  //   title: 'Indira Paganotto',
  //   buy:'https://tinyurl.com/mrxc6v5s'
  // },
  {
    image:parfait ,
    title: '999999999 / PARFAIT / DIØN / LORELY MUR',
    buy:'https://www.ticketfairy.com/event/999999999-parfait-dion?fbclid=PAZXh0bgNhZW0CMTEAAabiroO7MQK3tGJY040hHcpxAaDn4MLiO5DE0l3tUvWi_9pPPCWXvLUv1wI_aem_uA4qKjy831KQMbHYZi2gYw'
  },
  {
    image: wecomeback,
    title: 'Welcome Back Devil',
    buy:'https://www.ticketfairy.com/event/welcome-back-devil-20sep2024?fbclid=PAZXh0bgNhZW0CMTEAAabJO-fL7MEmJqFmfj-59rh72OjjORyuHlPnLpN9CVzIjnHzgtyEKpXLiNc_aem_Ru0qFMq63F_RpmT6HY12zQ'
  },
  {
    image:sasha ,
    title: 'Sasha and John Digweed',
    buy:'https://sjd.boletia.com/'
    
  },
  {
    image: mission,
    title: 'The Mission y Christian Death',
    buy:'https://www.eventrid.com.mx/eventos/atenea/the-mission-y-christian-death-en-mexico?utm_source=promo+atenea&utm_medium=sala_urbana&utm_campaign=web'
    
  },
  {
    image: pauloak,
    title: 'PAUL OAKENFOLD, 30 Aniversario',
    buy:'https://www.eventbrite.com.mx/e/paul-oakenfold-30-aniversario-perfecto-records-tour-tickets-950099151137'
  },
  {
    image:nocheav ,
    title: 'Noche de avivamiento, fuego y milagros con Juan Carlos Alvarado',
    buy:'https://noche-de-avivamiento-fuego-y-milagros-con-juan-carlos-alvarado.boletia.com/'
  },
];

export default function NextEvents() {
  return (
    <div className='NextEvents'>
      <Slider 
        items={nextEvents}
        handleClick={() => console.log('do nothing')}
      />
    </div>
  )
}
